<template>
  <LSection :use-margin="false">
    <template #body>
      <div class="chatbot">
        <div class="heading1">
          QT Strategy Chatbot
          <hr class="gradientLine" />
        </div>
        <LSection :use-margin="false">
          <template #body>
            <div class="text-justify">
              <div class="flex flex-col h8:flex-row gap-4">
                <div class="flex flex-grow-0">
                  <a
                    href="https://assistants.hybridintelligence.eu/quantumStrategies"
                    target="_blank"
                  >
                    <img src="/frame.png" alt="qr code" width="600" />
                  </a>
                </div>
                <div class="flex flex-col flex-grow">
                  <p>
                    Explore quantum strategies by country and year with the QT
                    Strategy Chatbot. The chatbot provides an interactive
                    platform to explore the quantum strategies of 62 countries,
                    including the U.S., Australia, and the European Union.
                    Discover key initiatives, compare strategies, and stay ahead
                    of global trends in quantum technology.
                  </p>
                  <br />
                  <h1 class="heading3">Warning: Alpha Testing in Progress</h1>
                  <AlertError
                    message="The QT Strategy Chatbot is currently in alpha testing. Many functionalities may not be fully optimized or functioning as intended. Please proceed with this in mind. Your feedback is appreciated as we continue to improve!"
                  />
                  <br />
                  <a
                    href="https://assistants.hybridintelligence.eu/quantumStrategies"
                    target="_blank"
                  >
                    <h2 class="gradientText">QT Strategy Chatbot</h2>
                  </a>
                  <br />
                  <a
                    href="https://assistants.hybridintelligence.eu/quantumStrategies"
                    target="_blank"
                    title="QT Strategy Chatbot"
                  >
                    <img
                      src="/QTSCUI.png"
                      width="600"
                      alt="QT Strategy Chatbot User Interface"
                    />
                  </a>
                </div>
              </div>

              <!--              <iframe src="https://assistants.hybridintelligence.eu/quantumStrategies" frameborder="0" class="w-full h-full" ></iframe>-->
            </div>
          </template>
        </LSection>
      </div>
    </template>
  </LSection>
</template>

<script>
import LSection from "@/components/layout/LSection.vue";
import { onMounted } from "vue";
import AlertError from "@/components/AlertError.vue";

export default {
  components: {
    AlertError,
    // JobMap,
    LSection,
  },
  setup() {
    const setHeadingColor = () => {
      const heading = document.getElementById("analysisHeader");
      // if (window.scrollY < 1340) {

      if (heading !== null && heading?.classList) {
        heading.classList.add("text-white");
        heading.classList.remove("text-black");
      }
      // } else {
      //   heading.classList.add('text-black')
      //   heading.classList.remove('text-white')
      // }
    };
    onMounted(() => {
      window.addEventListener("scroll", setHeadingColor);
    });
    return {};
  },
};
</script>

<style scoped></style>
